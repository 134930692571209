import { request } from '../network/request.js';
import qs from 'qs';

export function shopInfo(data) {
  // 商家注册
  return request({
    url: '/parking/saas/shopInfo/saveNotPWD',
    method: 'post',
    data: data,
  });
}

export function shopInfoList(query) {
  // 商家管理
  return request({
    url: '/parking/saas/shopInfo',
    method: 'get',
    params: query,
  });
}

export function deleteshopInfo(query) {
  // 商家删除
  return request({
    url: '/parking/saas/shopInfo/delete',
    method: 'get',
    params: query,
  });
}

export function shopInfoPk(query) {
  // 商家额度查询
  return request({
    url: '/parking/saas/shopInfoPk',
    method: 'get',
    params: query,
    paramsSerializer: (query) => qs.stringify(query),
  });
}

export function shopBuyLog(query) {
  // 查看购买记录
  return request({
    url: '/parking/saas/shopBuyLog/findByAndPkIdShopId',
    method: 'get',
    params: query,
  });
}

export function findByShopId(query) {
  // 根据shopId查看购买记录
  return request({
    url: '/parking/saas/shopBuyLog/findByShopId',
    method: 'get',
    params: query,
  });
}

export function shopCoupon(query) {
  // 商家优惠券
  return request({
    url: '/parking/saas/shopCoupon',
    method: 'get',
    params: query,
  });
}

export function shopBuyLogList(query) {
  // 商家购买记录
  return request({
    url: '/parking/saas/shopBuyLog',
    method: 'get',
    params: query,
  });
}

export function shopMoneyLogList(query, head) {
  // 商家购买记录
  return request({
    url: '/parking/saas/shopMoneyLog',
    headers: head,
    method: 'get',
    params: query,
  });
}

export function userCoupon(query) {
  // 用户领券记录查询
  return request({
    url: '/parking/saas/userCoupon',
    method: 'get',
    params: query,
  });
}

export function addshopCoupon(data) {
  // 商家添加优惠券
  return request({
    url: '/parking/saas/shopCoupon/save',
    method: 'post',
    data: data,
  });
}

export function shopInfoPkje(data) {
  // 新建商家购买记录
  return request({
    url: '/parking/saas/shopInfoPk/save',
    method: 'post',
    data: data,
  });
}

export function getRole() {
  // 查询是否为商家
  return request({
    url: '/parking/saas/shopInfo/getRole',
    method: 'get',
  });
}

export function findByIdxx(query) {
  // 商家身份-商家管理
  return request({
    url: '/parking/saas/shopInfo/findById',
    method: 'get',
    params: query,
  });
}

export function updateByShopIdxx(query) {
  // 商家身份-商家管理修改
  return request({
    url: '/parking/saas/shopInfo/updateByShopId',
    method: 'get',
    params: query,
  });
}

export function findByMoneyxx(data) {
  // 单个商家额度查询
  return request({
    url: '/parking/saas/shopInfoPk/findByMoney',
    method: 'post',
    data: data,
  });
}

export function exportShopInfo(data) {
  // 商家用户excel导出
  return request({
    url: '/parking/saas/shopInfo/exportShopInfo',
    method: 'post',
    data: data,
  });
}

export function exportShopInfopk(data) {
  // 商家额度查看excel导出
  return request({
    url: '/parking/saas/shopInfoPk/exportShopInfopk',
    method: 'post',
    data: data,
  });
}

export function exportShopcoupon(data) {
  // 优惠券导出
  return request({
    url: '/parking/saas/shopCoupon/exportShopcoupon',
    method: 'post',
    data: data,
  });
}

export function exportShopBuyLog(data) {
  // 商家购买记录excel导出
  return request({
    url: '/parking/saas/shopBuyLog/exportShopBuyLog',
    method: 'post',
    data: data,
  });
}

export function exportUserCoupon(data) {
  // 用户领券记录excel导出
  return request({
    url: '/parking/saas/userCoupon/excel',
    method: 'post',
    data: data,
  });
}

export function shopCouponfindById(query) {
  // 根据id查询优惠券
  return request({
    url: '/parking/saas/shopCoupon/findById',
    method: 'get',
    params: query,
  });
}

export function updateShoCoupon(data) {
  // 修改优惠券
  return request({
    url: '/parking/saas/shopCoupon/updateShoCoupon',
    method: 'post',
    data: data,
  });
}

export function shopCoupondelete(query) {
  // 删除优惠券
  return request({
    url: '/parking/saas/shopCoupon/delete',
    method: 'get',
    params: query,
  });
}

export function adduserCoupon(data) {
  // 手动发放优惠券
  return request({
    url: '/parking/saas/userCoupon',
    method: 'post',
    data: data,
  });
}

export function getElectronicCoupon() {
  // 可发放电子券列表
  return request({
    url: '/parking/saas/shopCoupon/getElectronicCoupon',
    method: 'get',
  });
}

export function userCouponId(id) {
  // 删除已发放优惠券
  return request({
    url: '/parking/saas/userCoupon/' + id,
    method: 'delete',
  });
}

export function updatePWD(data) {
  // 商家密码修改
  return request({
    url: '/parking/saas/shopInfo/updatePWD',
    method: 'post',
    data: data,
  });
}

export function getPkGroupByPkId(query) {
  // 根据pkid获取停车分组
  return request({
    url: '/parking/saas/shopCoupon/getPkGroupByPkId',
    method: 'get',
    params: query,
  });
}

export function getShopId() {
  // 查询商家信息
  return request({
    url: '/parking/saas/shopInfo/getShopId',
    method: 'get',
  });
}

export function shopCouponInvalid(query) {
  // 优惠券作废
  return request({
    url: '/parking/saas/shopCoupon/shopCouponInvalid',
    method: 'get',
    params: query,
  });
}

export function savePhysicalCoupon(query) {
  // 新增二维码
  return request({
    url: '/parking/saas/shopCoupon/savePhysicalCoupon',
    method: 'get',
    params: query,
  });
}

export function PhysicalCouponList(query) {
  // 查看二维码
  return request({
    url: '/parking/saas/shopCoupon/PhysicalCouponList',
    method: 'get',
    params: query,
  });
}

export function PhysicalCouponAll(query) {
  // 查看当前所有二维码
  return request({
    url: '/parking/saas/shopCoupon/PhysicalCouponAll',
    method: 'get',
    params: query,
  });
}
